import type { GenerateOrderPdf, GenerateOrderPdfParams } from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getGenerateOrderPdf(
    params: GenerateOrderPdfParams,
    options?: { signal: AbortSignal }
): Promise<GenerateOrderPdf | ApiResponse> {
    const { fetchSdApi } = fetchOrderDetermination();
    const url = `/generate-order-pdf`;
    const body = {
        data: {
            attributes: params,
            type: 'generate-order-pdf',
        },
    };

    try {
        const response = await fetchSdApi(url, {
            ...options,
            method: 'POST',
            body,
        }) as any;

        if (response.error) {
            return handleError(response);
        }

        return response.data.attributes;
    } catch (error) {
        return handleError(error);
    }
}
