import type {
    ReExportOrderParams
} from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getReExportOrders(
    params: ReExportOrderParams,
    options?: { signal: AbortSignal }
): Promise<null | ApiResponse> {
    const { fetchSdApi } = fetchOrderDetermination();
    const url = `/reexport-shipments`;
    const body = {
        data: {
            attributes: params,
            type: 'reexport-shipments',
        },
    };

    try {
        const response = await fetchSdApi(url, {
            ...options,
            method: 'POST',
            body,
        }) as any;

        if (response.status === 0) {
            return handleError(response);
        }

        return null;
    } catch (error) {
        return handleError(error);
    }
}
