import type {
    FileClusterFileTypeParams,
    FileUploadParams,
    GenerateBranchClustersPreview,
    GenerateOrderPdf,
    GenerateOrderPdfParams,
    GenerateWorkbookPdf,
    GenerateWorkbookPdfParams,
    ReExportOrderParams,
    UploadBranchClusterParams
} from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import getGenerateOrderPdf from '~/composables/api/searchDiscover/getGenerateOrderPdf';
import getReExportOrders from '~/composables/api/searchDiscover/getReExportOrders';
import getGenerateWorkbookPdf from '~/composables/api/searchDiscover/getGenerateWorkbookPdf';
import uploadBranchClusterFile from '~/composables/api/searchDiscover/uploadBranchClusterFile';
import getGenerateBranchClustersPreview
    from '~/composables/api/searchDiscover/getGenerateBranchClustersPreview';

export type AcceptedRequestParams = GenerateOrderPdfParams |
    GenerateWorkbookPdfParams |
    UploadBranchClusterParams |
    ReExportOrderParams |
    FileClusterFileTypeParams |
    null;
export type AcceptedRequestResponse = GenerateWorkbookPdf | GenerateOrderPdf | FileUploadParams | GenerateBranchClustersPreview;

export interface TransferItem {
    name: string;
    isLoading: boolean;
    isLoaded: boolean;
    isTransferred: boolean;
    hasError: boolean;
    request: {
        endpoint:
            'orders' |
            'reexport' |
            'workbook' |
            'branch-clusters' |
            'branch-clusters-generate-preview';
        params: AcceptedRequestParams | null;
        abortController: null | AbortController;
        response: null | AcceptedRequestResponse | ApiResponse;
    };
}

export default defineStore('transfers', () => {
    const toasts = useToasts();
    const { $t } = useNuxtApp();
    const items = ref([]) as Ref<TransferItem[]>;
    const loadedItems = computed(() => items.value.filter((item: TransferItem) => item.isLoaded));
    const errorItems = computed(() => items.value.filter((item: TransferItem) => item.hasError));
    const isInProgress = computed(() => items.value.length && loadedItems.value.length !== items.value.length);
    const isDone = computed(() => items.value.length && loadedItems.value.length === items.value.length);
    const hasErrors = computed(() => errorItems.value.length);

    const getErrorText = (endpoint: string): string => {
        switch (endpoint) {
        case 'orders':
            return $t('General.downloads_error_text');
        case 'branch-clusters':
            return $t('General.uploads_error_text');
        case 'reexport':
            return $t('General.reexport_trigger_failed');
        default:
            return $t('General.downloads_error_text');
        }
    };

    const runNextSyncItem = async() => {
        const nextItem = items.value.find((item: TransferItem) => !item.isLoaded);

        if (nextItem && !nextItem.isLoading) {
            nextItem.isLoading = true;
            nextItem.request.abortController = new AbortController();

            switch (nextItem.request.endpoint) {
            case 'orders':
                nextItem.request.response = await getGenerateOrderPdf(<GenerateOrderPdfParams>nextItem.request.params, {
                    signal: nextItem.request.abortController.signal
                });
                break;
            case 'reexport':
                nextItem.request.response = await getReExportOrders(<ReExportOrderParams>nextItem.request.params, {
                    signal: nextItem.request.abortController.signal
                });
                break;
            case 'workbook':
                nextItem.request.response = await getGenerateWorkbookPdf(<GenerateWorkbookPdfParams>nextItem.request.params, {
                    signal: nextItem.request.abortController.signal
                });
                break;
            case 'branch-clusters':
                nextItem.request.response = await uploadBranchClusterFile(<UploadBranchClusterParams>nextItem.request.params, {
                    signal: nextItem.request.abortController.signal
                });
                break;
            case 'branch-clusters-generate-preview':
                nextItem.request.response = await getGenerateBranchClustersPreview(<FileClusterFileTypeParams>nextItem.request.params,{
                    signal: nextItem.request.abortController.signal
                });
                break;
            default:
            }

            if (nextItem.request.response && 'state' in nextItem.request.response && nextItem.request.response?.state === 'failed') {
                nextItem.hasError = true;

                toasts.add({
                    type: 'error',
                    text: nextItem.request.response?.error?.detail ?? getErrorText(nextItem.request.endpoint),
                });
            } else if (nextItem.request.endpoint !== 'branch-clusters' && nextItem.request.response && 'error' in nextItem.request.response) {
                nextItem.hasError = true;
            }

            nextItem.isLoading = false;
            nextItem.isLoaded = true;
            runNextSyncItem();
        }
    };

    const add = (endpoint: TransferItem['request']['endpoint'], params: AcceptedRequestParams, name?: string) => {
        if (items.value.some(item => item.request.endpoint !== endpoint)) {
            items.value = [];
        }

        items.value.push({
            name: name || useDateFormat(new Date(), 'YYYY-MM-DD_HH-mm-ss'),
            isLoading: false,
            isLoaded: false,
            isTransferred: false,
            hasError: false,
            request: {
                endpoint,
                params,
                abortController: null,
                response: null,
            },
        });

        runNextSyncItem();
    };

    const retry = (item: TransferItem) => {
        item.request.response = null;
        item.hasError = false;
        item.isLoaded = false;
        runNextSyncItem();
    };

    const cancel = (item: TransferItem) => {
        if (item.isLoading && item.request.abortController) {
            item.request!.abortController.abort();
        }

        items.value = items.value.filter((itm: TransferItem) => itm !== item);
        runNextSyncItem();
    };

    const cancelAll = () => {
        items.value.map(cancel);
    };

    return {
        items,
        isInProgress,
        isDone,
        hasErrors,
        loadedItems,
        errorItems,
        add,
        cancel,
        retry,
        cancelAll,
    };
});
