import type { GenerateWorkbookPdf, GenerateWorkbookPdfParams } from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getGenerateWorkbookPdf(
    params: GenerateWorkbookPdfParams,
    options?: { signal: AbortSignal }
): Promise<GenerateWorkbookPdf | ApiResponse> {
    const url = `/generate-workbook-pdf?${useParamsToQuery(params)}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url, options) as any;

        if (response.error) {
            return handleError(response);
        }

        return response.data[0].attributes;
    } catch (error) {
        return handleError(error);
    }
}
