import type {
    FileClusterFileTypeParams,
    GenerateBranchClustersPreview
} from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getGenerateBranchClustersPreview(
    params: FileClusterFileTypeParams,
    options?: { signal: AbortSignal }
): Promise<GenerateBranchClustersPreview | ApiResponse> {
    const url = `/branch-clusters-generate-preview/${params.fileType}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url, options) as any;

        if (response.error) {
            return handleError(response);
        }

        return response.data.attributes;
    } catch (error) {
        return handleError(error);
    }
}
